<template>
  <div>
    <div class="heading">
      <h3>合作伙伴</h3>
      <p class="title">共创客户价值 驱动超级增长</p>
    </div>
    <div class="hand">
      <h6>携手微心动</h6>
      <p class="title">
        基于一体化营销云，为企业构建“以客户为中心”的数字化转型
      </p>
      <div class="content">
        <p>MarTech声量持续攀升市场需求升温</p>
        <p>业内期待服务标准和最佳实践</p>
        <p>企业需要能解决痛点的营销技术解决方案</p>
      </div>
      <div class="content_c">
        欢迎加入<span>Super Partner</span
        >r合作伙伴计划作为先行者为企业开启创新篇章
      </div>
      <div></div>
    </div>
    <div class="partner">
      <h6>为什么要成为微心动合作伙伴</h6>
      <img src="../../assets/images/wxd.png" alt="" />
    </div>
    <div class="plan">
      <h6>加入微心动超级合作伙伴计划</h6>
      <p>
        敏捷(Agility),赋能(Activation), 加速(Accelerption), 是Super
        Partner合作伙伴计划的核心价值点。<br />
        诚挚邀请中国的MarTech合作伙伴，在自身的核心能力上，叠加最前沿、可落地的营销与技术手段,以及先进的营销管理咨询理念，与微心动携手提供企业一
        体化营销云解决方案。
        通过培训与相关支持，可望拓展专业技能，增加生意机会，进而提升盈利能力。<br />
        欢迎成为微心动菁英级、认证级、开发级合作伙伴!
      </p>
      <img src="../../assets/images/wxd01.png" alt="" />
    </div>
     <div class="cost">
      <h4 class="head">支持与赋能</h4>
      <div class="cost_c">
        <div>
          <img src="../../assets/images/support01.png" />
          <h6>培训赋能</h6>
          <p>专属的工具包、线上线下系列培训、快速提升合作伙伴服务能力</p>
        </div>
        <div>
          <img src="../../assets/images/support02.png" />
          <h6>市场营销</h6>
          <p>行业论坛、技术沙龙、行业展会、媒体报道全面支持</p>
        </div>
        <div>
          <img src="../../assets/images/support03.png" />
          <h6>技术支持</h6>
          <p>专业团队支撑合作伙伴联合开发解决方案，及时响应</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/cooperation.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  span {
    font-weight: 600;
    font-size: 0.4rem;
  }
  .title {
    width: 7.43rem;
    margin: 0.62rem auto 0;
    font-size: 0.36rem;
    line-height: 0.3rem;
  }
  .heading_c {
    position: absolute;
    bottom: -2rem;
    right: 2.4rem;
    display: flex;
    width: 14.4rem;
    height: 2.7rem;
    background-image: url(../../assets/images/us.png);
    background-size: 100% 100%;
    div {
      width: 8.72rem;
      height: 2.7rem;
      text-align: left;
      box-sizing: border-box;
      padding: 0.7rem 0.88rem;
      h4 {
        font-size: 0.28rem;
      }
      p {
        line-height: 0.3rem;
        font-size: 0.22rem;
      }
    }
  }
}
.hand {
  padding: 1.36rem 0 0;
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  .title {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  .content {
    margin-top: 0.58rem;
    display: flex;
    padding: 0 2.3rem;
    justify-content: space-between;
    p {
      text-align: center;
      width: 4.8rem;
      height: 1.24rem;
      box-sizing: border-box;
      padding: 0.46rem 0 0.48rem;
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
      border-radius: 0.1rem;
      font-size: 0.22rem;
      font-weight: 600;
    }
  }
  .content_c {
    width: 14.6rem;
    margin: 0.28rem 2.3rem 0;
    padding: 0.4rem 0;
    box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
    border-radius: 0.1rem;
    text-align: center;
    font-size: 0.22rem;
    font-weight: 600;
    span {
      color: #ffc53d;
    }
  }
}
.partner {
  text-align: center;
  h6 {
    font-size: 0.28rem;
    padding: 1rem 0 0.58rem;
  }
  img {
    width: 14.4rem;
  }
}
.plan {
  text-align: center;
  h6 {
    font-size: 0.28rem;
    padding: 1rem 0 0.58rem;
  }
  p {
    padding: 0 1.83rem;
    text-align: left;
    font-size: 0.18rem;
    line-height: 0.27rem;
  }
  img {
    padding-top: 0.48rem;
    width: 15.56rem;
  }
}
.cost {
  padding: 1.1rem 0 1.2rem;
  .head {
    font-size: 0.28rem;
    line-height: 0.5rem;
    text-align: center;
  }
  .cost_c {
    padding: 0.58rem 3.46rem 0;
    display: flex;
    justify-content: space-between;
    div {
      width: 3.2rem;
      text-align: center;
      img {
        width: 0.8rem;
        height: 0.8rem;
      }
      h6 {
        padding-top: 0.20rem;
        font-size: 0.22rem;
        padding-bottom: 0.18rem;
      }
      p {
        font-size: 0.18rem;
        line-height: 0.27rem;
        color:#666 ;
      }
    }
  }
}
</style>
